import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";


// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-generali.png";
import { forgotPassword } from "store/actions";

const ForgetPasswordPage = props => {

  //meta title
  document.title=`Forget Password | ${process.env.REACT_APP_DOCUMENT_NAME}`;

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(forgotPassword(values));
    }
  });

  const { forgotPasswordMessage, forgotPasswordError} = useSelector(state => ({
    forgotPasswordError: state.Login.error,
    forgotPasswordMessage: state.Login.forgotPasswordMessage,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col>
                      <div className="text-white p-4">
                        <h5 className="text-white">Recupera Password</h5>
                        <p>Inserisci la tua email, ti invieremo le istruzioni per recuperare la password.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="25"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    
                    {forgotPasswordError ? <Alert color="danger">{forgotPasswordError}</Alert> : null}
                    {forgotPasswordMessage ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgotPasswordMessage}
                      </Alert>
                    ) : null} 

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                            disabled={!(validation.isValid && validation.dirty)}
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Ritorna al{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {process.env.REACT_APP_COPYRIGHT} {" "}
                  <i className="mdi mdi-heart text-danger" /> {process.env.REACT_APP_MADE}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
