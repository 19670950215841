import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";
import { appendParameters } from "./utility";

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
const postValidateToken = () => post(url.POST_VALIDATE_TOKEN);
const postPasswordForgot = data => post(url.POST_PASSWORD_FORGOT, data);
const getMe = () => get(url.GET_ME);

// Users
const getListUsers = data => get(`${url.GET_OTTIENI_PERITI}?${appendParameters(data)}` );
const postUser = data => post(url.POST_USER, data);
const deleteUser = id => del(`${url.DELETE_PERITO}/${id}`);
const putCollegaPeritoIspettore = id => put(`${url.PUT_COLLEGA_PERITO_ISPETTORE}/${id}`);
const putScollegaPeritoIspettore = id => put(`${url.PUT_SCOLLEGA_PERITO_ISPETTORE}/${id}`);

// Perizie
const getListPerizie = data => get(`${url.GET_PERIZIE}?${appendParameters(data)}` );

// Update User
const putCambiaEmail = data => put(`${url.PUT_CAMBIA_EMAIL}`, data);
const putCambiaPassword = data => put(`${url.PUT_CAMBIA_PASSWORD}`, data);
const putCambiaUserData = data => put(`${url.PUT_CAMBIA_USER_DATA}`, data);

export {
    postLogin,
    postValidateToken,
    getMe,
    getListUsers,
    deleteUser,
    postUser,
    getListPerizie,
    postPasswordForgot,
    putCollegaPeritoIspettore,
    putScollegaPeritoIspettore,
    putCambiaEmail,
    putCambiaPassword,
    putCambiaUserData
}