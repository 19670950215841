//LOGIN
export const POST_LOGIN = "/v1/auth/login";
export const POST_VALIDATE_TOKEN = "/v1/auth/refresh-token";
export const POST_PASSWORD_FORGOT = "/v1/auth/recupera-password";
export const GET_ME = "/v1/auth/me";

//ADMIN
export const GET_OTTIENI_PERITI = "/admin/ottieni-periti";
export const DELETE_PERITO = "/admin/cancella-utente";
export const POST_USER = "/admin/crea-utente";
export const GET_PERIZIE = "/admin/ottieni-perizie";

//PERITI
export const PUT_COLLEGA_PERITO_ISPETTORE = "/v1/periti/collega-perito-ispettore";
export const PUT_SCOLLEGA_PERITO_ISPETTORE = "/v1/periti/scollega-perito-ispettore";

//USER UPDATE
export const PUT_CAMBIA_EMAIL = "/v1/auth/cambia-email";
export const PUT_CAMBIA_PASSWORD = "/v1/auth/cambia-password";
export const PUT_CAMBIA_USER_DATA = "/v1/auth/cambia-dati";
// export const PUT_SCOLLEGA_PERITO_ISPETTORE = "/v1/periti/scollega-perito-ispettore";
